<template>
  <div class="title-drawer">
    <el-dialog
      :title="data.title"
      :visible.sync="dialogTableVisible"
      @close="close"
      width="540px"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <div v-if="dialogTableVisible">
        <div
          class="sub-title"
          v-if="show"
          style="color: #4ca6f9; text-indent: 30px; margin-bottom: 10px;word-break: break-word;"
        >
          {{$t('clientTypePage.themeFormat')}}
        </div>
        <div>
          <els-form ref="titleform" :column="elsNewData" v-model="form" :ctx="{lang: $t('d')}">
          </els-form>
        </div>
      </div>
      <div style="text-align: right">
        <el-button @click="close">{{$t('d.cancel')}}</el-button>
        <el-button type="primary" @click="handClick" :loading="isSure"
          >{{$t('d.confirm')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import elsForm from "../../../components/els-form/main.vue";
import { treeMap } from "../../../utils/tree.js";
export default {
  name: "title-drawer",
  components: { elsForm },
  props: {
    data: Object,
    elsData: Array,
    show: Boolean,
    rowData: Object,
  },
  data: () => {
    return {
      isSure: false,
      dialogTableVisible: false,
      form: {},
      titleResult: {},
      isEdit: false,
      tRes: {}, // 用来存储展示的值
      columnData: [],
    };
  },
  computed: {
    elsNewData() {
      if (this.isEdit) {
        const d = this.elsData;
        let col = treeMap(
          d,
          (n) => {
            if (n.prop && n.prop === "key") {
              n.disabled = true;
            }
          },
          { children: "cls" }
        );
        return col;
      } else {
        const d = this.elsData;
        let col = treeMap(
          d,
          (n) => {
            if (n.prop && n.prop === "key") {
              n.disabled = false;
            }
          },
          { children: "cls" }
        );
        return col;
      }
    },
  },
  watch: {
    rowData(r) {
      this.form = { ...r };
    },
  },
  created() {
    const d = this.rowData;
    if (!!d && d.credentialsType) {
      this.form = {
        confirmStyle: d.credentialsValue,
        credentialsId: d.credentialsId,
      };
    } else {
      this.form = this.rowData;
    }
  },
  methods: {
    async handClick() {
      this.isSure = true;
      const app = this.$refs.titleform;
      try {
        app && (await app.validate());
        const f = !!this.form.credentialsId ? this.form : this.tRes;
        if (this.data.tSign) {
          this.$emit("add", this.form);
        } else {
          this.$emit("add", f);
        }
        // this.$refs.titleform.resetFormData();
      } catch (e) {
        console.log(e);
      }
      setTimeout(() => {
        this.isSure = false;
      }, 1000);
    },
    close() {
      this.isSure = false;
      const o = Object.keys(this.titleResult);
      this.dialogTableVisible = false;
      this.$refs.titleform.clearValidate();
      if (o.length > 0) {
      } else {
        this.$refs.titleform.resetFormData();
      }
    },
  },
};
</script>
<style scoped lang='scss'>
::v-deep .el-select {
  width: 100% !important;
}
::v-deep .el-input__icon {
  line-height: 32px !important;
}
.title-drawer {
  .el-dialog__body {
    .sub-title {
      background: #cfecf5;
      padding: 10px;
      margin-bottom: 10px;
      text-indent: 10px;
      color: #4ca6f9 !important;
    }
  }
}
</style>
