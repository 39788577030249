<template>
  <div class="client-myTab2">
    <div class="client-select">
      <span class="client-name">{{lang.deviceRange}}</span>
      <el-select v-model="value" :disabled="isDis" :placeholder="lang.pleaseSelect">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="client-main">
      <div class="container-header">
        <span
          >{{ clientName }}
          <el-button
            size="mini"
            icon="el-icon-plus"
            circle
            v-if="value !== '1'"
            @click="add"
          ></el-button>
          <el-button
            size="mini"
            icon="el-icon-delete"
            v-if="value !== '1'"
            circle
            @click="delAttr"
          ></el-button>
          <el-button
            size="mini"
            icon="el-icon-refresh"
            circle
            @click="refresh"
          ></el-button>
        </span>
        <span>
          <el-input
            v-model="sValue"
            :placeholder="lang.searchInput"
            suffix-icon="el-icon-search"
          ></el-input>
        </span>
      </div>
      <els-table
        ref="tabTable"
        :column="column"
        :data="latestTelemetryData"
        style="height: 500px"
        @change="delCheck"
        :showCheckbox="true"
        :showIndex="false"
        :menuWidth="'100px'"
        :filter="utilFilter"
        :filterValue="sValue"
        idKey="key"
      >
        <template v-slot:menu="{ row }" v-if="value != 1">
          <el-button :disabled="noEditNames.includes(row.key)" type="text" icon="el-icon-edit" @click="editRow(row)"></el-button>
        </template>
      </els-table>
      <title-drawer
        ref="titleD"
        :data="titleData"
        :elsData="elsform"
        :rowData="rowD"
        @add="addAttr"
      ></title-drawer>
    </div>
  </div>
</template>
<script>
import elsTable from "../../../components/els-table/main.vue";
import titleDrawer from "../clientType/title-drawer.vue";
import {
  addAttribute,
  delAttribute,
  getAttribute,
} from "../../../api/base/client";
import mixinsI18n from "@/mixins/i18n.js"
export default {
  name: "client-myTab2",
  mixins: [mixinsI18n],
  components: { elsTable, titleDrawer },
  props: {
    isData: Object,
  },
  computed: {
    isDis() {
      const col = this.isData.sign === "1" ? false : true;
      return col;
    },
    noEditNames(){
      return 'inactivityAlarmTime,active,lastActivityTime,lastDisconnectTime,lastConnectTime'.split(',')
    },
    options(){
      const data = [
        { value: "1", label: this.lang.clientPro },
        { value: "2", label: this.lang.serverPro },
        { value: "3", label: this.lang.sharePro },
      ]
      return data
    },
    column() {
      const data = [
                {
                  prop: "lastUpdateTs",
                  label: this.lang.lastUpdate,
                  valueType: "date",
                  sortable: true,
                },
                { prop: "key", label: this.lang.key,sortable: true },
                { prop: "value", label: this.lang.value,sortable: true, },
              ]
      return data;
    },
    elsform() {
      const data = [
                {
                  tag: "div",
                  title: this.lang.basicPro,
                  cls: [
                    {
                      label: this.lang.key,
                      tag: "el-input",
                      prop: "key",
                      required: true,
                      disabled: true,
                      // rules: [
                      //   {
                      //     pattern: /^\w{0,20}$/,
                      //     message: this.lang.terNumMsg,
                      //     trigger: "blur",
                      //   },
                      // ],
                    },
                    {
                      label: this.lang.value,
                      tag: "el-input",
                      prop: "value",
                      required: true,
                      // rules: [
                      //   {
                      //     pattern: /^\w{0,20}$/,
                      //     message: this.lang.terNumMsg,
                      //     trigger: "blur",
                      //   },
                      // ],
                    },
                  ],
                },
              ];
      return data
    }
  },
  data: () => {
    return {
      PAGE_NAME: "clientManagePage",
      latestTelemetryData: [],
      savelatestTelemetryData: [],
      rowD: {},
      dialogVisible: false,
      titleData: {
        title: "",
        tSign: 1,
        data: {},
      },
      value: "1",
      sValue: "",
      clientName: "",
      total: 0,
      newKey: {
        keys: "",
      },
    };
  },
  watch: {
    value: {
      handler(v) {
        this.sValue = "";
        if (v === "1") {
          // this.connect();
          this.latestTelemetryData = this.savelatestTelemetryData;
        }
        const d = this.options.filter((o) => {
          return o.value === v;
        });
        if (d) {
          this.clientName = d[0].label;
        }
        this.connect();
      },
    },
    sValue(v) {
      this.$refs.tabTable.page.pageNum = 1;
      if (!!v) {
        this.latestTelemetryData = this.savelatestTelemetryData.filter(
          (item) => {
            return item.key.indexOf(v) > -1 || item.value.indexOf(v) > -1;
          }
        );
      } else {
        this.value === "1"
          ? (this.latestTelemetryData = this.savelatestTelemetryData)
          : this.connect();
        // this.connect();
      }
    },
  },
  mounted() {
    this.value =
      this.isData.sign === "2" ? this.options[1].value : this.options[0].value;
    this.clientName =
      this.isData.sign === "2" ? this.options[1].label : this.options[0].label;
    this.connect();
  },
  methods: {
    utilFilter(list, key) {
      return list.filter(
        (item) => Object.values(item).join(",").indexOf(key) != -1
      );
    },
    addAttr(v) {
      this.sValue = "";
      const d = {
        entityType: this.isData.nId.entityType,
        entityId: this.isData.nId.id,
        scope: this.value === "2" ? "SERVER_SCOPE" : "SHARED_SCOPE",
      };
      const p = {};
      // p[b[0]] = b[1];
      p[v.key] = v.value;
      addAttribute(d, p).then(() => {
        this.$refs.titleD.dialogTableVisible = false;
        this.connect();
        this.$refs.tabTable.apiSetPageNum();
      });
    },
    add() {
      // 添加属性
      this.titleData.title = this.lang.addAttr;
      this.$refs.titleD.dialogTableVisible = true;
      this.$refs.titleD.isEdit = false;
      this.rowD = {};
    },
    refresh() {
      this.sValue = "";
      this.$refs.tabTable.page.pageNum = 1;
      this.connect();
    },
    // 删除属性值
    delAttr() {
      // console.log('.....',this.newKey)
      if (!!this.newKey.keys) {
        const d = {
          entityType: this.isData.nId.entityType || this.isData.id.entityType,
          entityId: this.isData.nId.id || this.isData.id.id,
          scope: this.value === "2" ? "SERVER_SCOPE" : "SHARED_SCOPE",
        };
        this.$confirm(this.lang.delMsg, this.lang.tips, {
          confirmButtonText: this.lang.confirm,
          cancelButtonText: this.lang.cancel,
          type: "warning",
        }).then(() => {
          delAttribute(d, this.newKey).then(() => {
            this.$message({
              message: this.lang.delSuccess,
              type: "success",
            });
            this.newKey = "";
            this.$refs.tabTable.page.pageNum = 1;
            this.connect();
          });
        });
      } else {
        this.$message({
          message: this.lang.delNotNull,
          type: "warning",
        });
      }
    },
    delCheck(check) {
      // console.log("check",check)
      const keys = check.map((e) => {
        return e.key;
      });
      this.newKey = {
        keys: keys.join(","),
      };
    },
    handleCallback(res = {}) {
      const r = Object.keys(res).map((k) => ({
        key: k,
        value: res[k][0][1],
        lastUpdateTs: res[k][0][0],
      }));
      r.sort((a, b) => {
        return a.lastUpdateTs < b.lastUpdateTs ? 1 : -1;
      });
      this.latestTelemetryData = r;
      this.savelatestTelemetryData = this.latestTelemetryData.concat();
    },
    async connect() {
      try {
        let d = [];
        if (this.isData.sign === "1") {
          if (this.value === "1") {
            const { id, entityType } = this.isData.nId;
            this.$socket.getData(
              id,
              entityType,
              "CLIENT_SCOPE",
              this.handleCallback
            );
          } else {
            this.$socket.reset();
            const p = {
              entityType: this.isData.nId.entityType,
              entityId: this.isData.nId.id,
              scope: this.value === "2" ? "SERVER_SCOPE" : "SHARED_SCOPE",
            };
            await getAttribute({ ...p }).then((res) => {
              res.sort((a, b) => {
                return a.lastUpdateTs < b.lastUpdateTs ? 1 : -1;
              });
              this.latestTelemetryData = res;
            });
          }
        } else {
          //资产管理
          const p = {
            entityType: this.isData.nId.entityType,
            entityId: this.isData.nId.id,
            scope: "SERVER_SCOPE",
          };
          await getAttribute({ ...p }).then((res) => {
            res.sort((a, b) => {
              return a.lastUpdateTs < b.lastUpdateTs ? 1 : -1;
            });
            this.latestTelemetryData = res;
          });
        }
      } catch (e) {
        console.log(e);
      }
      return {};
    },
    editRow(row) {
      // 编辑
      this.titleData.title = this.lang.editAttr;
      this.$refs.titleD.isEdit = true;
      this.$refs.titleD.dialogTableVisible = true;
      this.$refs.titleD.form = { ...row };
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     this.rowD = { ...row };
      //   }, 0);
      // });
    },
  },
  destroyed() {
    this.$socket.reset();
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-select .el-input .el-select__caret {
  line-height: 32px !important;
}
::v-deep .el-input__icon {
  line-height: 32px;
}
::v-deep .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
}
::v-deep .v-modal {
  position: none;
}
.client-myTab2 {
  .client-name {
    display: inline-block;
    margin-right: 20px;
  }
  .client-main {
    margin-top: 16px;
    .container-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }
}
</style>
